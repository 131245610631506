<!-- =========================================================================================
  File Name: FullPage.vue
  Description: Full page layout
  ----------------------------------------------------------------------------------------
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="layout--full-page">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  methods: {
    loadGoogleAnalytics () {
      if (!document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=G-RQ3DDVPNCK"]')) {
        const script = document.createElement('script')
        script.async = true
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-RQ3DDVPNCK'
        document.head.appendChild(script)
        script.onload = function () {
          window.dataLayer = window.dataLayer || []
          function gtag () {
            window.dataLayer.push(arguments)
          }
          gtag('js', new Date())
          gtag('config', 'G-RQ3DDVPNCK')
        }
      }
    }
  },
  created () {
    localStorage.setItem('locale', this.$route.params.lang)
    if (this.$route.params.lang === 'en' || this.$cookies.get('language') === 'en') {
      this.$i18n.locale = 'en'
      this.$vs.rtl = false
    } else {
      this.$i18n.locale = 'ar'
      this.$vs.rtl = true
    }
    if (this.$route.name === 'page-sign-up') {
      this.loadGoogleAnalytics()
    }
  }
}
</script>

<style>
[dir=rtl] .vs-input--input.normal {
  padding: 5.6px !important;
}
iframe[title="Message from company"],
iframe[title="Close message"] {
  display: none !important;
}
#launcher {
  display: none;
}
</style>
